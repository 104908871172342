import styled from 'styled-components';
import { shade, tint, desaturate, math } from 'polished';

/** @component */
export const Button = styled.button<{ block?: boolean }>`
  background: ${props => props.theme.colors.accent};
  border: 1px solid transparent;
  color: ${props => props.theme.colors.brandSecondary};
  cursor: pointer;
  font-size: ${props => props.theme.font.size.md};
  font-weight: 700;
  line-height: ${props => props.theme.font.lineHeight};
  min-width: 7em;
  padding: ${props => `${math(`${props.theme.spacing.md} * 0.75`)} ${props.theme.spacing.md}`};
  transition: background ${({ theme: { transition } }) => `${transition.duration} ${transition.timing}`};
  white-space: nowrap;
  ${props => props.block ? 'width: 100%' : ''}

  &:hover,
  &:focus {
    background: ${props => tint(0.2, props.theme.colors.accent)};
  }

  &:active {
    background: ${props => shade(0.1, props.theme.colors.accent)};
  }

  &:disabled {
    background: ${props => desaturate(1, props.theme.colors.accent)};
    color: ${props => desaturate(1, props.theme.colors.brandSecondary)};
    cursor: not-allowed;
  }
`;

export const ButtonSecondary = styled(Button)`
  background: ${props => props.theme.colors.white};
  border: 1px solid;
  color: ${props => props.theme.colors.brandPrimary};
  transition: color ${({ theme: { transition } }) => `${transition.duration} ${transition.timing}`};

  &:hover,
  &:focus {
    background: ${props => props.theme.colors.white};
    color: ${props => tint(0.1, props.theme.colors.brandPrimary)};
  }

  &:active {
    background: ${props => props.theme.colors.white};
    color: ${props => shade(0.1, props.theme.colors.brandPrimary)};
  }

  &:disabled {
    background: ${props => props.theme.colors.white};
    color: ${props => desaturate(1, props.theme.colors.brandPrimary)};
    cursor: not-allowed;
  }
`;
