import React from 'react';
import styled from 'styled-components';
import { tint, math } from 'polished';

import { Icon } from 'components/content/Icon/Icon';

import { InputWrapper } from 'components/canvas/InputWrapper/InputWrapper';

const InputIcon = styled.div`
  align-items: center;
  display: flex;
  margin-left: ${props => props.theme.spacing.md};
  position: relative;
  width: 0;
`;

const InputEl = styled.input`
  background: none;
  border: 0 none;
  font-size: inherit;
  line-height: ${props => props.theme.font.lineHeight};
  padding: ${props => `${math(`${props.theme.spacing.md} * 0.75`)} ${props.theme.spacing.md}`};
  text-overflow: ellipsis;
  width: 100%;

  &:not(:only-child) {
    text-indent: ${props => props.theme.spacing.sm};
  }

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: ${props => tint(0.5, props.theme.input.color)};
  }

  &:disabled {
    background: ${props => props.theme.input.bg.disabled};
    cursor: not-allowed;
    user-select: none;
  }
`;

interface InputProps extends React.PropsWithoutRef<JSX.IntrinsicElements['input']> {
  icon?: string;
  disabled?: boolean;
  state?: 'positive' | 'negative';
  ref?: any;
}

export const Input = React.forwardRef((
  { icon, disabled, state, ...props }: InputProps, ref,
) => (
  <InputWrapper disabled={disabled} state={state}>
    {icon ? (
      <InputIcon>
        <Icon type={icon} />
      </InputIcon>
    ) : null}
    <InputEl disabled={disabled} ref={ref} {...props} />
  </InputWrapper>
));

Input.displayName = 'Input';
