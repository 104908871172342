import React from 'react';
import ReactModal from 'react-modal';
import styled, { createGlobalStyle } from 'styled-components';
import { transparentize, lighten } from 'polished';

import { Icon } from 'components/content/Icon/Icon';

interface ModalProps {
  children: React.ReactNode;
  onRequestClose: any;
  [x: string]: any;
}

const ModalStyles = createGlobalStyle`
  .ReactModal__Body--open { // sass-lint:disable-line class-name-format
    height: 100vh;
    overflow: hidden;
  }

  .modal {
    -webkit-overflow-scrolling: touch;
    background: ${props => props.theme.colors.white};
    box-shadow: 0 0 5px 0 ${props => props.theme.colors.darken};
    max-height: 100%;
    max-width: 25em;
    opacity: 0;
    outline: none;
    overflow: auto;
    transform: scale(0.9);
    transition: opacity ${props => `${props.theme.transition.duration} ${props.theme.transition.timing}`},
    transform ${props => `${props.theme.transition.duration} ${props.theme.transition.timing}`};
    transition-delay: ${props => props.theme.transition.duration};
    z-index: 1000;
    width: 100%;

    @media screen and (min-width: ${props => props.theme.breakpoint.tablet}) {
      margin: ${props => props.theme.spacing.md};
      min-height: auto;
    }
  }

  .modal.ReactModal__Content--after-open {
    opacity: 1;
    transform: none;
  }

  .modal.ReactModal__Content--before-close {
    opacity: 0;
    transform: scale(0.9);
    transition-delay: 0s;
  }

  .modal__overlay {
    align-items: center;
    background-color: transparent;
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    transition: background-color ${props => `${props.theme.transition.duration} ${props.theme.transition.timing}`};
    z-index: 10;
  }

  .modal__overlay.ReactModal__Overlay--after-open {
    background-color: ${props => transparentize(0.5, props.theme.colors.background)};
  }

  .modal__overlay.ReactModal__Overlay--before-close {
    background-color: transparent;
    transition-delay: ${props => props.theme.transition.duration};
  }
`;

const Close = styled.button`
  appearance: none;
  background: none;
  border: 0 none;
  border-radius: 0;
  box-sizing: content-box;
  color: ${props => props.theme.colors.brandPrimary};
  cursor: pointer;
  float: right;
  height: 24px;
  margin: 5% 5% 0 0;
  padding: ${props => props.theme.spacing.md};
  transition: background-color ${props => `${props.theme.transition.duration} ${props.theme.transition.timing}`};
  width: 24px;

  &:hover,
  &:focus,
  &:active {
    background: ${props => props.theme.colors.darken};
    color: ${props => lighten(0.1, props.theme.colors.brandPrimary)};
    outline: 0 none;
  }
`;

const Content = styled.div`
  padding: 10%;
`;

export const Modal = ({ children, onRequestClose, ...props }: ModalProps) => (
  <>
    <ModalStyles />
    <ReactModal
      className="modal"
      overlayClassName="modal__overlay"
      onRequestClose={onRequestClose}
      closeTimeoutMS={400}
      {...props}
    >
      {onRequestClose ? (
        <Close onClick={onRequestClose} type="button">
          <Icon type="close" size="large" />
        </Close>
      ) : null}
      <Content>
        {children}
      </Content>
    </ReactModal>
  </>
);
