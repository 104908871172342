import React from 'react';
import {
  ApolloClient, ApolloLink, ApolloProvider, HttpLink, InMemoryCache,
} from '@apollo/client';
import * as Sentry from '@sentry/browser';
import fetch from 'isomorphic-unfetch';
import getConfig from 'next/config';

let apolloClient = null;
const { publicRuntimeConfig: { apiUrl, sentryDsn } } = getConfig();
Sentry.init({ dsn: sentryDsn });

/**
 * Creates and configures the ApolloClient
 * @param  {Object} [initialState={}]
 */
// eslint-disable-next-line @typescript-eslint/default-param-last
function createApolloClient(initialState = {}, ctx) {
  const isBrowser = typeof window !== 'undefined';

  const enchancedFetch = (url, opts) => {
    const headers = {
      ...opts.headers,
      Cookie: ctx.req ? ctx.req.headers.cookie : '',
    };

    return fetch(url, {
      ...opts,
      headers,
    });
  };

  // Check out https://github.com/zeit/next.js/pull/4611 if you want to use the AWSAppSyncClient
  return new ApolloClient({
    ssrMode: !isBrowser, // Disables forceFetch on the server (so queries are only run once)
    link: ApolloLink.from([
      new HttpLink({
        uri: apiUrl, // Server URL (must be absolute)
        credentials: 'include', // Additional fetch() options like `credentials` or `headers`
        fetch: isBrowser ? fetch : enchancedFetch,
      }),
    ]),
    cache: new InMemoryCache().restore(initialState),
  });
}

/**
 * Always creates a new apollo client on the server
 * Creates or reuses apollo client in the browser.
 * @param  {Object} initialState
 */
function initApolloClient(initialState, ctx) {
  // Make sure to create a new client for every server-side request so that data
  // isn't shared between connections (which would be bad)
  if (typeof window === 'undefined') {
    return createApolloClient(initialState, ctx);
  }

  // Reuse client on the client-side
  if (!apolloClient) {
    apolloClient = createApolloClient(initialState, ctx);
  }

  return apolloClient;
}

/**
 * Creates and provides the apolloContext
 * to a next.js PageTree. Use it by wrapping
 * your PageComponent via HOC pattern.
 * @param {Function|Class} PageComponent
 * @param {Object} [config]
 * @param {Boolean} [config.ssr=true]
 */
export function withApollo(PageComponent, { ssr = true } = {}) {
  // eslint-disable-next-line no-shadow
  const WithApollo = ({ apolloClientProp, apolloState, ...pageProps }: { apolloClientProp: any; apolloState: any; }) => {
    const client = apolloClientProp || initApolloClient(apolloState, pageProps);
    return (
      <ApolloProvider client={client}>
        <PageComponent {...pageProps} />
      </ApolloProvider>
    );
  };

  // Set the correct displayName in development
  if (process.env.NODE_ENV !== 'production') {
    const displayName = PageComponent.displayName || PageComponent.name || 'Component';

    if (displayName === 'App') {
      // eslint-disable-next-line no-console
      console.warn('This withApollo HOC only works with PageComponents.');
    }

    WithApollo.displayName = `withApollo(${displayName})`;
  }

  if (ssr || PageComponent.getInitialProps) {
    WithApollo.getInitialProps = async ctx => {
      if (ctx.ctx.req) {
        console.log({ headers: ctx.ctx.req.headers }); // Header logging for SSR
      }

      const { AppTree } = ctx;

      // Initialize ApolloClient, add it to the ctx object so
      // we can use it in `PageComponent.getInitialProp`.
      // eslint-disable-next-line no-multi-assign, no-shadow
      const apolloClientLocal = (ctx.apolloClient = initApolloClient({}, ctx.ctx));
      ctx.ctx.apolloClient = apolloClientLocal;

      // Run wrapped getInitialProps methods
      let pageProps = {};
      if (PageComponent.getInitialProps) {
        pageProps = await PageComponent.getInitialProps(ctx);
      }

      // Only on the server:
      if (typeof window === 'undefined') {
        // When redirecting, the response is finished.
        // No point in continuing to render
        if (ctx.res && ctx.res.finished) {
          return pageProps;
        }

        // Only if ssr is enabled
        if (ssr) {
          try {
            // Run all GraphQL queries
            const { getDataFromTree } = await import('@apollo/client/react/ssr');
            await getDataFromTree(
              <AppTree
                pageProps={{
                  ...pageProps,
                  apolloClientLocal,
                }}
              />,
            );
          } catch (error) {
            // Prevent Apollo Client GraphQL errors from crashing SSR.
            // Handle them in components via the data.error prop:
            // https://www.apollographql.com/docs/react/api/react-apollo.html#graphql-query-data-error
            if (sentryDsn) {
              Sentry.captureException(error);
            } else {
              // eslint-disable-next-line no-console
              const exception = error?.graphQLErrors
                ? error.graphQLErrors[0]?.extensions?.exception : null;
              console.error('Error while running `getDataFromTree`', error, exception);
            }
          }
        }
      }

      // Extract query data from the Apollo store
      const apolloState = apolloClientLocal.cache.extract();

      return {
        ...pageProps,
        apolloState,
      };
    };
  }

  return WithApollo;
}
