import React from 'react';
import styled from 'styled-components';
import { math } from 'polished';
import { InputWrapper } from 'components/canvas/InputWrapper/InputWrapper';
import { Icon } from 'components/content/Icon/Icon';

interface SelectProps {
  children: React.ReactNode;
  compact?: boolean;
}

export const StyledSelect = styled(InputWrapper)<{ compact?: boolean }>`
  position: relative;

  select {
    appearance: none;
    background: transparent;
    border: 0 none;
    border-radius: 0;
    color: ${props => props.theme.colors.brandSecondary};
    cursor: pointer;
    font-size: inherit;
    line-height: ${props => props.theme.font.lineHeight};
    padding: ${props => props.compact
    ? `${props.theme.spacing.xs} 2.5em ${props.theme.spacing.xs} ${props.theme.spacing.sm}`
    : `${math(`${props.theme.spacing.md} * 0.75`)} ${props.theme.spacing.md}`};
    text-overflow: ellipsis;
    width: 100%;
    z-index: 1;
  }

  select::-ms-expand {
    display: none;
  }
`;

const Arrow = styled.div<{ compact?: boolean }>`
  background: ${props => props.theme.colors.white};
  border-left: 1px solid ${props => props.theme.colors.border};
  position: absolute;
  right: 0;
  top: 0;
  padding: ${props => props.compact
    ? `${props.theme.spacing.xs} ${props.theme.spacing.sm}`
    : `${math(`${props.theme.spacing.md} * 0.75`)} ${props.theme.spacing.md}`};
  height: 100%;
`;

export const Select = ({ children, compact }: SelectProps) => (
  <StyledSelect compact={compact}>
    {children}
    <Arrow compact={compact}>
      <Icon type="arrow-down" color="brandPrimary" size={compact ? 'md' : 'lg'} />
    </Arrow>
  </StyledSelect>
);
