import React, { useState } from 'react';
import styled from 'styled-components';
import { math, transparentize } from 'polished';
import { useQuery, gql } from '@apollo/client';
import { Highlight } from 'react-highlighter-ts';

import { List } from 'components/composition/List/List';
import { Input } from 'components/controls/Input/Input';

interface AutocompleteProps extends React.PropsWithoutRef<JSX.IntrinsicElements['input']> {
  field: string;
  value?: string;
  handleChange?: (value: string) => void;
}

export const AUTOCOMPLETE_QUERY = gql`
  query autocomplete($field: AutocompleteField!, $value: String!) {
    autocomplete(field: $field, value: $value)
  }
`;

const Results = styled(List)`
  background: ${props => props.theme.colors.white};
  border-bottom: 2px solid ${props => props.theme.colors.brandPrimary};
  box-shadow: 0 4px 8px rgba(2, 33, 76, .2);
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  z-index: 10;
`;

const ResultItem = styled(List.Item)<{ isActive?: boolean }>`
  background: ${props => props.isActive && props.theme.input.bg.default};
  border-bottom: 2px solid ${props => props.theme.colors.border};
  color: ${props => props.theme.colors.brandSecondary};
  cursor: pointer;
  padding: ${props => `${math(`${props.theme.spacing.md} * 0.75`)} ${props.theme.spacing.md}`};
  margin-bottom: 0;

  &:hover {
    background: ${props => transparentize(0.9, props.theme.colors.brandPrimary)};
  }

  &:last-child {
    border-bottom: none;
  }
`;

const Container = styled.div`
  display: flex;
  position: relative;
  width: 100%;

  ${Results} {
    display: none;
  }

  &:focus-within {
    ${Results} {
      display: block;
    }
  }
`;

export const Autocomplete = ({ field, value, handleChange, ...props }: AutocompleteProps) => {
  const [activeResult, setActiveResult] = useState(0);
  const [autoActive, setAutoActive] = useState(false);
  const hasValue = value && value.length > 1;
  const { data } = useQuery(AUTOCOMPLETE_QUERY, {
    variables: {
      field,
      value: hasValue ? value : '', // wait for 3 letters first
    },
    context: {
      debounceKey: field,
      debounceTimeout: 750,
    },
    skip: !hasValue,
  });

  const handleChangeAndReset = (newValue: string) => {
    handleChange(newValue);
    setActiveResult(0);
  };

  const handleKeyDown = (event: any) => {
    setAutoActive(true);
    if (!hasValue || !data || !data.autocomplete.length) {
      return true;
    }

    if (event.keyCode === 40) { // down
      event.preventDefault();
      if (activeResult !== (data.autocomplete.length - 1)) {
        setActiveResult(activeResult + 1);
      }
    }

    if (event.keyCode === 38) { // up
      event.preventDefault();
      if (activeResult !== 0) {
        setActiveResult(activeResult - 1);
      }
    }

    if (event.keyCode === 13) {
      event.preventDefault();
      setAutoActive(false);
      handleChangeAndReset(data.autocomplete[activeResult]);
    }

    return true;
  };

  return (
    <Container>
      <Input
        onChange={e => handleChangeAndReset(e.target.value)}
        value={value}
        onKeyDown={e => handleKeyDown(e)}
        list="autocompleteOff"
        autoComplete="off"
        {...props}
      />
      {data && data.autocomplete.length > 0 && autoActive && (
        <Results>
          {data.autocomplete.map((result, index) => {
            const isActive = activeResult === index;

            return (
              <ResultItem
                key={`${result}${Math.random()}`}
                onClick={() => {
                  setAutoActive(false);
                  handleChangeAndReset(result);
                }}
                isActive={isActive}
              >
                <Highlight search={value} matchElement="b">{result}</Highlight>
              </ResultItem>
            );
          })}
        </Results>
      )}
    </Container>
  );
};
