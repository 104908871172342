import styled from 'styled-components';

const Group = styled.div<{ horizontal?: boolean; inline?: boolean }>`
  ${props => props.horizontal ? 'align-items: baseline;' : ''}
  display: ${props => props.horizontal ? 'flex' : 'block'};
  flex-wrap: wrap;
  ${props => !props.inline ? `
    margin-bottom: ${props.theme.spacing.md};

    ${props.horizontal ? `
      @media screen and (min-width: ${props.theme.breakpoint.tablet}) {
        margin-bottom: ${props.theme.spacing.lg};
      }
    ` : ''}
  ` : ''}
`;

const Label = styled.label`
  color: ${props => props.theme.colors.brandSecondary};
  display: block;
  font-size: ${props => props.theme.font.size.sm};
  font-weight: 700;
  min-width: 100%;
  padding: ${props => `${props.theme.spacing.sm} ${props.theme.spacing.md}`};
  padding-left: 0;
  text-transform: uppercase;

  @media screen and (min-width: ${props => props.theme.breakpoint.tablet}) {
    min-width: 10em;
  }

  small {
    color: ${props => props.theme.colors.bizmark};
    display: block;
    font-size: 1em;
    font-weight: 400;
    text-transform: none;
  }
`;

const Content = styled.div`
  flex: 1 1 auto;
`;

const Help = styled.div<{ state?: 'positive' | 'negative' }>`
  ${props => props.state ? `color: ${props.theme.colors[`${props.state}`]};` : ''}
  font-size: ${props => props.theme.font.size.sm};
  margin-top: ${props => props.theme.spacing.sm};
`;


export const FormGroup = Object.assign(Group, { Label, Content, Help });
