import React from 'react';
import styled from 'styled-components';

const SpinnerInner = styled.span<{ size?: string }>`
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  animation: spin .75s linear infinite;
  border: 2px solid ${props => props.theme.colors.border};
  border-radius: 50%;
  border-top-color: ${props => props.theme.colors.brandPrimary};
  display: inline-block;
  line-height: 1;
  height: ${props => props.size || '1em'};
  vertical-align: middle;
  width: ${props => props.size || '1em'};
`;

const SpinnerOuter = styled.span<{ show?: boolean; size?: string }>`
  @keyframes fade {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  animation: fade .2s ease-in-out 1 backwards;
  animation-delay: .5s;
  display: inline-block;
  transition: width ${({ theme: { transition } }) => `${transition.duration} ${transition.timing}`} .2s;
  width: ${props => props.show ? `${props.size || '1em'}` : '0'};

  ${SpinnerInner} {
    opacity: ${props => props.show ? '1' : '0'};
    transition: opacity ${({ theme: { transition } }) => `${transition.duration} ${transition.timing}`} .2s;
  }
`;

export const Spinner = ({ show = false, size }: { show?: boolean; size?: string }) => (
  <SpinnerOuter show={show} size={size}>
    <SpinnerInner size={size} />
  </SpinnerOuter>
);
