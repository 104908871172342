// Use this in the autocomplete attribute to prevent autocomplete
// https://stackoverflow.com/questions/15738259/disabling-chrome-autofill
export function noAuto() {
  // const length = Math.floor(Math.random() * 20) + 5;
  // let result = '';
  // const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  // const charactersLength = characters.length;
  // for (let i = 0; i < length; i += 1) {
  //   result += characters.charAt(Math.floor(Math.random() * charactersLength));
  // }
  // return result;
  // Doesn't seem to work any more, but this does
  return 'off';
}
