import React from 'react';
import styled from 'styled-components';
import { transparentize } from 'polished';

const StyledNav = styled.nav<{ isOpen?: boolean }>`
  display: flex;
  height: ${props => props.isOpen ? '2.75em' : '0'};
  justify-content: flex-end;
  margin: 0 -${props => props.theme.spacing.root};
  overflow: hidden;
  transition: height ${({ theme: { transition } }) => `${transition.duration} ${transition.timing}`};

  @media screen and (min-width: ${props => props.theme.breakpoint.nav}) {
    height: auto;
    margin-left: 0;
    margin-right: -${props => props.theme.spacing.md};
    width: auto;
  }
`;

const Ul = styled.ul`
  display: flex;
  flex: 1 1 auto;
  list-style: none;
  margin: 0;
  padding: 0;

  @media screen and (min-width: ${props => props.theme.breakpoint.nav}) {
    flex: 0 0 auto;
  }
`;

/** @component */
export const Nav = ({ isOpen, children }: { isOpen: boolean; children: React.ReactNode }) => (
  <StyledNav isOpen={isOpen}>
    <Ul>
      {children}
    </Ul>
  </StyledNav>
);

Nav.Item = styled.li<{ active: boolean }>`
  flex: 1 1 33.33%;

  @media screen and (min-width: ${props => props.theme.breakpoint.nav}) {
    flex: 0 0 auto;
  }

  a {
    border: 2px solid transparent;
    ${props => props.active ? `
      border-bottom-color: ${props.theme.colors.white};
      pointer-events: none;
    ` : ''}
    border-width: 2px 0;
    display: block;
    text-decoration: none;
    background: ${props => props.active ? props.theme.colors.darken : 'transparent'};
    color: ${props => props.theme.colors.white};
    padding: ${props => `${props.theme.spacing.sm} ${props.theme.spacing.md}`};
    text-align: center;
    transition: background ${props => `${props.theme.transition.duration} ${props.theme.transition.timing}`},
    border ${props => `${props.theme.transition.duration} ${props.theme.transition.timing}`};

    &:hover {
      background: ${props => transparentize(0.025, props.theme.colors.darken)};
      color: ${props => props.theme.colors.white};
      border-bottom-color: ${props => transparentize(0.5, props.theme.colors.white)};
    }

    @media screen and (min-width: ${props => props.theme.breakpoint.nav}) {
      background: transparent;
      border-bottom-color: transparent;
      padding: ${props => props.theme.spacing.md};
      ${props => props.active ? `
        border-top-color: ${props.theme.colors.white};
      ` : ''}

      &:hover {
        background: transparent;
        border-bottom-color: transparent;
        border-top-color: ${props => transparentize(0.5, props.theme.colors.white)};
      }
    }
  }

`;
