import styled from 'styled-components';
import { shade } from 'polished';

interface InputWrapperProps {
  disabled?: boolean;
  state?: string;
}

export const InputWrapper = styled.div<InputWrapperProps>`
  align-items: center;
  background: ${props => props.theme.input.bg.default};
  border: 1px solid ${props => props.state ? props.theme.colors[`${props.state}`] : props.theme.input.border.color};
  color: ${props => props.theme.input.color};
  display: flex;
  font-size: ${props => props.theme.font.size.md};
  position: relative;
  transition: border ${({ theme: { transition } }) => `${transition.duration} ${transition.timing}`},
    background ${({ theme: { transition } }) => `${transition.duration} ${transition.timing}`};
  width: 100%;
  z-index: 1;

  &:hover,
  &:focus-within {
    border-color: ${props => props.disabled ? props.theme.input.border.color : shade(0.1, props.theme.input.border.color)}
  }

  &:focus-within {
    outline: 1px dotted #212121;
    outline: 5px auto -webkit-focus-ring-color;
  }
`;
