import { DefaultTheme } from 'styled-components';

const palette = {
  white: '#fff',
  background: '#f4f7f9',
  border: '#d6e7f6',
  horizon: '#5a81ab',
  bizmark: '#4b6a88',
  brandPrimary: '#1e8bc3',
  brandSecondary: '#02214c',
  accent: '#f4cf12',
  positive: '#47ab4f',
  negative: '#df4a4e',
  darken: 'rgba(0, 0, 0, .075)',
  lighten: 'rgba(255, 255, 255, .1)',
};

export const theme: DefaultTheme = {
  breakpoint: {
    xs: '0em',
    twoCol: '30em',
    tablet: '48em',
    desktop: '60em',
    max: '75em',
    nav: '32em',
  },
  colors: palette,
  containerMaxWidth: '75em',
  smallContainerMaxWidth: '32em',
  font: {
    family: 'Lato, "Lucida Grande", Tahoma, Sans-Serif',
    lineHeight: 1.5,
    size: {
      sm: '0.875em',
      md: '1em',
      lg: '1.25em',
      xl: '1.5em',
      jumbo: '3.25em',
    },
  },
  heading: {
    font: {
      family: 'Lato, "Lucida Grande", Tahoma, Sans-Serif',
      lineHeight: 1.25,
      size: {
        h1: '2.5em',
        h2: '1.75em',
        h3: '1.25em',
        h4: '1.125em',
        h5: '1em',
        h6: '0.875em',
      },
    },
  },
  input: {
    bg: {
      default: palette.background,
      disabled: palette.background,
    },
    border: {
      color: palette.border,
    },
    color: palette.brandSecondary,
    label: {
      color: 'inherit',
      fontSize: '1em',
    },
    placeholder: palette.horizon,
    padding: '0.5em',
  },
  spacing: {
    xs: '0.25em',
    sm: '0.5em',
    md: '1em',
    lg: '2em',
    max: '4rem',
    fluid: '5%',
    root: '5vw',
  },
  transition: {
    duration: '0.2s',
    timing: 'ease-in-out',
  },
};
