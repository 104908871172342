import React from 'react';
import styled from 'styled-components';

// Get new icons from https://material.io/resources/icons/

interface IconProps {
  /**
   * arrow-left, arrow-up, arrow-down, arrow-right, calendar, close,
   * destination, key, money-bag, sort, user, weight, globe, timeline,
   * track, podium, heart, menu, menu-open, search
   */
  type?: string;
  color?: string;
  size?: string;
}

const StyledIcon = styled.span<{ empty?: boolean; color?: string; size?: string }>`
  display: inline-block;
  color: ${props => props.color && props.theme.colors[props.color]};
  height: ${props => props.size ? props.theme.font.size[props.size] : '1em'};
  min-width: ${props => props.size ? props.theme.font.size[props.size] : '1em'};
  vertical-align: text-top;
  width: ${props => props.size ? props.theme.font.size[props.size] : '1em'};

  ${props => props.empty && `
    background: currentColor;
    border-radius: 50%;
    opacity: .5;
  `};
`;

const Svg = styled.svg<{ size? : string }>`
  height: ${props => props.size ? props.theme.font.size[props.size] : '1em'};
  width: ${props => props.size ? props.theme.font.size[props.size] : '1em'};
`;

const IconSvg = ({ type, size }: { type: string; size: string }) => {
  switch (type) {
    case 'arrow-left': return (
      <Svg size={size} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path fill="currentColor" d="M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z" />
        <path fill="none" d="M0 0h24v24H0V0z" />
      </Svg>
    );
    case 'arrow-up': return (
      <Svg size={size} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path fill="currentColor" d="M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z" />
        <path d="M0 0h24v24H0z" fill="none" />
      </Svg>
    );
    case 'arrow-right': return (
      <Svg size={size} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path fill="currentColor" d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z" />
        <path fill="none" d="M0 0h24v24H0V0z" />
      </Svg>
    );
    case 'arrow-down': return (
      <Svg size={size} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path fill="currentColor" d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z" />
        <path fill="none" d="M0 0h24v24H0V0z" />
      </Svg>
    );
    case 'calendar': return (
      <Svg size={size} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128">
        <g>
          <path fill="currentColor" d="M92.3 20.7v-8.5H81.5v8.5H46.6v-8.5H35.8v8.5H7v101h114v-101H92.3zM35.9 31.5V40h10.8v-8.5h34.9V40h10.8v-8.5h18V49H17.9V31.5h18zm-18 79.6V59.7h92.5v51.4H17.9z" />
          <path fill="currentColor" d="M54.5 69.7h19.1v10.8H54.5zM27.5 69.7h19.1v10.8H27.5zM81.6 69.7h19.1v10.8H81.6zM54.5 90.5h19.1v10.8H54.5zM27.5 90.5h19.1v10.8H27.5zM81.6 90.5h19.1v10.8H81.6z" />
        </g>
      </Svg>
    );
    case 'close': return (
      <Svg size={size} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path fill="currentColor" d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
        <path d="M0 0h24v24H0z" fill="none" />
      </Svg>
    );
    case 'destination': return (
      <Svg size={size} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128">
        <g>
          <path fill="currentColor" d="M82.1 58.1c-1.2-1.7-3.6-2-5.2-.8L64.1 67l-12.8-9.6c-1.7-1.2-4-.9-5.2.8-1.2 1.7-.9 4 .8 5.2l11 8.3-11 8.2c-1.7 1.2-2 3.6-.7 5.2.7 1 1.9 1.5 3 1.5.8 0 1.6-.2 2.2-.8l12.8-9.6L77 85.8c.7.5 1.5.8 2.2.8 1.1 0 2.3-.5 3-1.5 1.2-1.7.9-4-.8-5.2l-11-8.2 11-8.2c1.6-1.3 1.9-3.7.7-5.4z" />
          <path fill="currentColor" d="M101.6 26.6H90.1c-.3 2.6-.8 5.1-1.6 7.5h7l19 82.5h-101l19-82.5h7c-.7-2.4-1.3-4.9-1.6-7.5H26.6L4.1 124.1h120l-22.5-97.5z" />
          <path fill="currentColor" d="M64.1 56.6s18.8-15 18.8-33.8C82.9 12.4 74.5 4 64.1 4s-18.8 8.4-18.8 18.8c0 18.8 18.8 33.8 18.8 33.8zm-7.5-33.7c0-4.1 3.4-7.5 7.5-7.5s7.5 3.4 7.5 7.5-3.4 7.5-7.5 7.5-7.5-3.4-7.5-7.5z" />
        </g>
      </Svg>
    );
    case 'key': return (
      <Svg size={size} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path fill="currentColor" d="M12.65 10C11.83 7.67 9.61 6 7 6c-3.31 0-6 2.69-6 6s2.69 6 6 6c2.61 0 4.83-1.67 5.65-4H17v4h4v-4h2v-4H12.65zM7 14c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z" />
        <path d="M0 0h24v24H0z" fill="none" />
      </Svg>
    );
    case 'menu': return (
      <Svg size={size} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
        <path d="M0 0h24v24H0z" fill="none" />
        <path fill="currentColor" d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z" />
      </Svg>
    );
    case 'menu-open': return (
      <Svg size={size} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path fill="currentColor" d="M3 18h13v-2H3v2zm0-5h10v-2H3v2zm0-7v2h13V6H3zm18 9.59L17.42 12 21 8.41 19.59 7l-5 5 5 5L21 15.59z" />
      </Svg>
    );
    case 'money-bag': return (
      <Svg size={size} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128">
        <g>
          <path fill="currentColor" d="M48.4 29.1H80l2.9-4.1c2.4-3.6 5-7 7.2-10.6 1.1-1.7 3.1-4.5 2.3-6.7-.6-1.6-2-2.1-3.5-2.4-1.1-.2-6.8-1.6-9.1-1.2-2 .4-3.9 1.4-5.9 2.1-2.8 1-5.8 1.8-8.8 1.6-2.6-.1-5.6-.5-8.1-1.4-2-.7-4-1.8-6.2-2.2-1.9-.3-8.7.6-10 .9-9.6 1.7-2.6 9 4.8 19.9l2.8 4.1zm69.7 57.8C110 68.2 95.3 47.9 79.9 38H48C32.7 47.9 18 68.2 9.9 86.9 1.8 105.6 11.5 124 30.1 124h67.8c18.6 0 28.3-18.4 20.2-37.1zM67 103.8v7.6h-6v-7.1c-4.6-.1-9.3-1.5-11.9-3.2l2-6.9c2.9 1.7 7 3.2 11.5 3.2 4.6 0 7.8-2.3 7.8-5.8 0-3.3-2.6-5.4-8-7.4-7.7-2.8-12.7-6.3-12.7-13.2 0-6.3 4.4-11.2 11.8-12.5v-7.2h6v6.8c4.6.1 7.8 1.3 10.2 2.4l-2 6.8c-1.8-.8-5-2.5-10-2.5-5.2 0-7 2.6-7 5.2 0 3 2.6 4.7 8.9 7.2 8.2 3.1 11.9 7.1 11.9 13.6-.1 6.2-4.4 11.7-12.5 13z" />
        </g>
      </Svg>
    );
    case 'search': return (
      <Svg size={size} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
        <path fill="currentColor" d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" />
        <path d="M0 0h24v24H0z" fill="none" />
      </Svg>
    );
    case 'sort': return (
      <Svg size={size} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
        <path fill="currentColor" d="M26.2 19.8c0 .3-.1.6-.4.9l-8.9 8.9c-.3.3-.6.4-.9.4-.3 0-.6-.1-.9-.4l-8.9-8.9c-.3-.3-.4-.6-.4-.9s.1-.6.4-.9c.3-.3.6-.4.9-.4h17.8c.3 0 .6.1.9.4.3.3.4.6.4.9zm0-7.6c0 .3-.1.6-.4.9-.3.3-.6.4-.9.4H7.1c-.3 0-.6-.1-.9-.4-.3-.3-.4-.6-.4-.9 0-.3.1-.6.4-.9l8.9-8.9c.3-.3.6-.4.9-.4.3 0 .6.1.9.4l8.9 8.9c.3.2.4.5.4.9z" />
      </Svg>
    );
    case 'asc': return (
      <Svg size={size} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill="currentColor" d="M26.2 12.2C26.2 12.5 26.1 12.8 25.8 13.1C25.5 13.4 25.2 13.5 24.9 13.5H7.1C6.8 13.5 6.5 13.4 6.2 13.1C5.9 12.8 5.8 12.5 5.8 12.2C5.8 11.9 5.9 11.6 6.2 11.3L15.1 2.4C15.4 2.1 15.7 2 16 2C16.3 2 16.6 2.1 16.9 2.4L25.8 11.3C26.1 11.5 26.2 11.8 26.2 12.2Z" />
      </Svg>
    );
    case 'desc': return (
      <Svg size={size} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill="currentColor" d="M26.2 19.8C26.2 20.1 26.1 20.4 25.8 20.7L16.9 29.6C16.6 29.9 16.3 30 16 30C15.7 30 15.4 29.9 15.1 29.6L6.2 20.7C5.9 20.4 5.8 20.1 5.8 19.8C5.8 19.5 5.9 19.2 6.2 18.9C6.5 18.6 6.8 18.5 7.1 18.5H24.9C25.2 18.5 25.5 18.6 25.8 18.9C26.1 19.2 26.2 19.5 26.2 19.8Z" />
      </Svg>
    );
    case 'user': return (
      <Svg size={size} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path fill="currentColor" d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z" />
        <path d="M0 0h24v24H0z" fill="none" />
      </Svg>
    );
    case 'weight': return (
      <Svg size={size} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128">
        <g>
          <path fill="currentColor" fillRule="evenodd" d="M101.6 44.8H72.4l-.3-1.5c7-3.1 11.9-10.1 11.9-18.2 0-11-9-19.9-20-19.9s-20 9-20 20c0 8.1 4.9 15 11.9 18.1l-.3 1.5H26.4l-17.6 78h110.4l-17.6-78zM52.2 25.2c0-6.5 5.3-11.8 11.8-11.8 6.5 0 11.8 5.3 11.8 11.8 0 4.1-2.1 7.8-5.4 9.9-1.2-1.5-3.6-2.5-6.5-2.5-2.8 0-5.3 1-6.5 2.5-3.1-2.2-5.2-5.8-5.2-9.9z" clipRule="evenodd" />
        </g>
      </Svg>
    );
    case 'globe': return (
      <Svg size={size} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path d="M0 0h24v24H0z" fill="none" />
        <path fill="currentColor" d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zm6.93 6h-2.95c-.32-1.25-.78-2.45-1.38-3.56 1.84.63 3.37 1.91 4.33 3.56zM12 4.04c.83 1.2 1.48 2.53 1.91 3.96h-3.82c.43-1.43 1.08-2.76 1.91-3.96zM4.26 14C4.1 13.36 4 12.69 4 12s.1-1.36.26-2h3.38c-.08.66-.14 1.32-.14 2 0 .68.06 1.34.14 2H4.26zm.82 2h2.95c.32 1.25.78 2.45 1.38 3.56-1.84-.63-3.37-1.9-4.33-3.56zm2.95-8H5.08c.96-1.66 2.49-2.93 4.33-3.56C8.81 5.55 8.35 6.75 8.03 8zM12 19.96c-.83-1.2-1.48-2.53-1.91-3.96h3.82c-.43 1.43-1.08 2.76-1.91 3.96zM14.34 14H9.66c-.09-.66-.16-1.32-.16-2 0-.68.07-1.35.16-2h4.68c.09.65.16 1.32.16 2 0 .68-.07 1.34-.16 2zm.25 5.56c.6-1.11 1.06-2.31 1.38-3.56h2.95c-.96 1.65-2.49 2.93-4.33 3.56zM16.36 14c.08-.66.14-1.32.14-2 0-.68-.06-1.34-.14-2h3.38c.16.64.26 1.31.26 2s-.1 1.36-.26 2h-3.38z" />
      </Svg>
    );
    case 'timeline': return (
      <Svg size={size} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path fill="currentColor" d="M23 8c0 1.1-.9 2-2 2-.18 0-.35-.02-.51-.07l-3.56 3.55c.05.16.07.34.07.52 0 1.1-.9 2-2 2s-2-.9-2-2c0-.18.02-.36.07-.52l-2.55-2.55c-.16.05-.34.07-.52.07s-.36-.02-.52-.07l-4.55 4.56c.05.16.07.33.07.51 0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2c.18 0 .35.02.51.07l4.56-4.55C8.02 9.36 8 9.18 8 9c0-1.1.9-2 2-2s2 .9 2 2c0 .18-.02.36-.07.52l2.55 2.55c.16-.05.34-.07.52-.07s.36.02.52.07l3.55-3.56C19.02 8.35 19 8.18 19 8c0-1.1.9-2 2-2s2 .9 2 2z" />
      </Svg>
    );
    case 'track': return (
      <Svg size={size} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path fill="none" d="M0 0h24v24H0V0z" />
        <path fill="currentColor" d="M19.07 4.93l-1.41 1.41C19.1 7.79 20 9.79 20 12c0 4.42-3.58 8-8 8s-8-3.58-8-8c0-4.08 3.05-7.44 7-7.93v2.02C8.16 6.57 6 9.03 6 12c0 3.31 2.69 6 6 6s6-2.69 6-6c0-1.66-.67-3.16-1.76-4.24l-1.41 1.41C15.55 9.9 16 10.9 16 12c0 2.21-1.79 4-4 4s-4-1.79-4-4c0-1.86 1.28-3.41 3-3.86v2.14c-.6.35-1 .98-1 1.72 0 1.1.9 2 2 2s2-.9 2-2c0-.74-.4-1.38-1-1.72V2h-1C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10c0-2.76-1.12-5.26-2.93-7.07z" />
      </Svg>
    );
    case 'external': return (
      <Svg size={size} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path fill="none" d="M0 0h24v24H0z" />
        <path fill="currentColor" d="M19 19H5V5h7V3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2v-7h-2v7zM14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3h-7z" />
      </Svg>
    );
    case 'podium': return (
      <Svg size={size} xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 64 64">
        <path fill="currentColor" d="M62,44.9H43.5V26.1c0-0.8-0.7-1.5-1.5-1.5H22c-0.8,0-1.5,0.7-1.5,1.5v6.4H2c-0.8,0-1.5,0.7-1.5,1.5v27.8c0,0.8,0.7,1.5,1.5,1.5h20c0,0,0,0,0,0h20c0,0,0,0,0,0h20c0.8,0,1.5-0.7,1.5-1.5V46.4C63.5,45.6,62.8,44.9,62,44.9z M3.5,35.5h17v24.8h-17V35.5z M23.5,34v-6.4h17v18.8v13.8h-17V34z M60.5,60.3h-17V47.9h17V60.3z" />
        <path fill="currentColor" d="M47.4,36.6L46.8,40c-0.1,0.6,0.1,1.1,0.6,1.5c0.5,0.3,1.1,0.4,1.6,0.1l3-1.6l3,1.6c0.2,0.1,0.5,0.2,0.7,0.2c0.3,0,0.6-0.1,0.9-0.3c0.5-0.3,0.7-0.9,0.6-1.5l-0.6-3.4l2.4-2.4c0.4-0.4,0.6-1,0.4-1.5s-0.6-0.9-1.2-1l-3.4-0.5l-1.5-3.1c-0.3-0.5-0.8-0.8-1.3-0.8s-1.1,0.3-1.3,0.8l-1.5,3.1l-3.4,0.5c-0.6,0.1-1,0.5-1.2,1c-0.2,0.5,0,1.1,0.4,1.5L47.4,36.6z M50.4,34.1c0.5-0.1,0.9-0.4,1.1-0.8l0.5-1l0.5,1c0.2,0.4,0.6,0.7,1.1,0.8l1.1,0.2L54,35c-0.4,0.3-0.5,0.8-0.4,1.3l0.2,1.1l-1-0.5c-0.2-0.1-0.5-0.2-0.7-0.2c-0.2,0-0.5,0.1-0.7,0.2l-1,0.5l0.2-1.1c0.1-0.5-0.1-1-0.4-1.3l-0.8-0.8L50.4,34.1z" />
        <path fill="currentColor" d="M6.8,23.2l-0.7,4.1c-0.1,0.6,0.1,1.1,0.6,1.5c0.5,0.3,1.1,0.4,1.6,0.1L12,27l3.7,1.9c0.2,0.1,0.5,0.2,0.7,0.2c0.3,0,0.6-0.1,0.9-0.3c0.5-0.3,0.7-0.9,0.6-1.5l-0.7-4.1l3-2.9c0.4-0.4,0.6-1,0.4-1.5c-0.2-0.5-0.6-0.9-1.2-1l-4.1-0.6l-1.8-3.7c-0.3-0.5-0.8-0.8-1.3-0.8s-1.1,0.3-1.3,0.8l-1.8,3.7l-4.1,0.6c-0.6,0.1-1,0.5-1.2,1s0,1.1,0.4,1.5L6.8,23.2z M10,20c0.5-0.1,0.9-0.4,1.1-0.8l0.8-1.7l0.8,1.7c0.2,0.4,0.6,0.8,1.1,0.8l1.9,0.3l-1.4,1.3c-0.4,0.3-0.5,0.8-0.4,1.3l0.3,1.9l-1.7-0.9c-0.2-0.1-0.5-0.2-0.7-0.2s-0.5,0.1-0.7,0.2l-1.7,0.9l0.3-1.9c0.1-0.5-0.1-1-0.4-1.3l-1.4-1.3L10,20z" />
        <path fill="currentColor" d="M27.3,14.2l-0.9,5.4c-0.1,0.6,0.1,1.1,0.6,1.5c0.5,0.3,1.1,0.4,1.6,0.1l4.9-2.6l4.9,2.6c0.2,0.1,0.5,0.2,0.7,0.2c0.3,0,0.6-0.1,0.9-0.3c0.5-0.3,0.7-0.9,0.6-1.5l-0.9-5.4l3.9-3.8c0.4-0.4,0.6-1,0.4-1.5c-0.2-0.5-0.6-0.9-1.2-1L37.2,7l-2.4-4.9c-0.3-0.5-0.8-0.8-1.3-0.8c-0.6,0-1.1,0.3-1.3,0.8L29.6,7l-5.5,0.8c-0.6,0.1-1,0.5-1.2,1c-0.2,0.5,0,1.1,0.4,1.5L27.3,14.2zM30.8,9.9c0.5-0.1,0.9-0.4,1.1-0.8l1.4-2.9L34.8,9C35,9.5,35.5,9.8,36,9.9l3.2,0.5l-2.3,2.3c-0.4,0.3-0.5,0.8-0.4,1.3l0.6,3.2l-2.9-1.5c-0.4-0.2-1-0.2-1.4,0l-2.9,1.5l0.6-3.2c0.1-0.5-0.1-1-0.4-1.3l-2.3-2.3L30.8,9.9z" />
      </Svg>
    );
    case 'heart': return (
      <Svg size={size} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path fill="none" d="M0 0h24v24H0z" />
        <path fill="currentColor" d="M16.5 3c-1.74 0-3.41.81-4.5 2.09C10.91 3.81 9.24 3 7.5 3 4.42 3 2 5.42 2 8.5c0 3.78 3.4 6.86 8.55 11.54L12 21.35l1.45-1.32C18.6 15.36 22 12.28 22 8.5 22 5.42 19.58 3 16.5 3zm-4.4 15.55l-.1.1-.1-.1C7.14 14.24 4 11.39 4 8.5 4 6.5 5.5 5 7.5 5c1.54 0 3.04.99 3.57 2.36h1.87C13.46 5.99 14.96 5 16.5 5c2 0 3.5 1.5 3.5 3.5 0 2.89-3.14 5.74-7.9 10.05z" />
      </Svg>
    );
    case 'upload': return (
      <Svg size={size} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
        <path d="M0 0h24v24H0z" fill="none" />
        <path fill="currentColor" d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM14 13v4h-4v-4H7l5-5 5 5h-3z" />
      </Svg>
    );
    default: return null;
  }
};

/** @component */
const Icon = ({ type, color, size }: IconProps) => (
  <StyledIcon empty={!type} color={color} size={size}>
    <IconSvg type={type} size={size} />
  </StyledIcon>
);


export { Icon, StyledIcon };
