import React from 'react';
import styled from 'styled-components';

const Nav = styled.nav``;

const Ul = styled.ul`
  list-style: none;
  padding: 0;
  margin: ${props => props.theme.spacing.lg} 0;
`;

/** @component */
export const FooterNav = ({ children }: { children: React.ReactNode }) => (
  <Nav>
    <Ul>
      {children}
    </Ul>
  </Nav>
);

FooterNav.Item = styled.li<{ external?: boolean }>`
  margin-bottom: ${props => props.theme.spacing.sm};

  a {
    text-decoration: none;
  }
`;
