import styled from 'styled-components';
import { lighten } from 'polished';

export const SmallPrint = styled.div`
  color: ${props => props.theme.colors.bizmark};
  font-size: ${props => props.theme.font.size.sm};
  margin-top: ${props => props.theme.spacing.fluid};

  a {
    color: ${props => props.theme.colors.bizmark};
    display: inline-block;
    text-decoration: underline;

    &:hover {
      color: ${props => lighten(0.1, props.theme.colors.brandPrimary)};
    }
  }
`;
