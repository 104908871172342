import styled from 'styled-components';

const StyledList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

const Item = styled.li<{ spacing?: string }>`
  margin-bottom: ${props => props.theme.spacing[props.spacing || 'sm']};
`;

export const List = Object.assign(StyledList, { Item });
