import React from 'react';
import styled from 'styled-components';
import Link from 'next/link';

const StyledLogo = styled.a<{ alternate?: boolean }>`
  color: ${props => props.alternate ? props.theme.colors.brandPrimary : props.theme.colors.white};
  cursor: pointer;
  display: block;
  font-weight: bold;
  line-height: 1.25;
  width: 8em;
  text-transform: uppercase;

  ${props => !props.alternate && `
    &:hover {
      color: ${props.theme.colors.white};
    }
  `};
`;

/** @component */
export const Logo = ({ alternate }: { alternate?: boolean }) => (
  <Link href="/" passHref>
    <StyledLogo alternate={alternate}>
      The Food Waste Atlas
    </StyledLogo>
  </Link>
);
