import React from 'react';
import styled from 'styled-components';

import { Wrap } from 'components/composition/Wrap/Wrap';

/** @component */
const StyledHeader = styled.header`
  background: ${props => props.theme.colors.brandPrimary};
  padding: ${props => `0 ${props.theme.spacing.root}`};

  @media screen and (min-width: ${props => props.theme.breakpoint.max}) {
    padding: ${props => `0 ${props.theme.spacing.md}`};
  }

  ${Wrap} {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
`;

const Logo = styled.div`
  padding: ${props => props.theme.spacing.md} 0;

  @media screen and (min-width: ${props => props.theme.breakpoint.nav}) {
    padding: 0;
  }
`;

const MobileNav = styled.div`
  flex: 0 1 auto;

  @media screen and (min-width: ${props => props.theme.breakpoint.nav}) {
    display: none;
  }
`;

const Nav = styled.div`
  flex-grow: 1;
  width: 100%;

  @media screen and (min-width: ${props => props.theme.breakpoint.nav}) {
    width: auto;
  }
`;

export const Header = ({ children }: { children: React.ReactNode }) => (
  <StyledHeader>
    <Wrap>
      {children}
    </Wrap>
  </StyledHeader>
);

Header.Logo = Logo;
Header.MobileNav = MobileNav;
Header.Nav = Nav;
