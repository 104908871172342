import React from 'react';
import App, { AppProps } from 'next/app';
import Link from 'next/link';
import Head from 'next/head';
import { withRouter } from 'next/router';
import styled, { ThemeProvider } from 'styled-components';
import { Normalize } from 'styled-normalize';
import nextCookies from 'next-cookies';
import Modal from 'react-modal';
import * as Sentry from '@sentry/browser';
import getConfig from 'next/config';

import { withApollo } from 'lib/apollo';
import { theme } from 'lib/theme';

import { GlobalStyle } from 'components/GlobalStyle';
import { Main } from 'components/composition/Main/Main';
import { Header } from 'components/canvas/Header/Header';
import { Footer } from 'components/canvas/Footer/Footer';
import { Icon } from 'components/content/Icon/Icon';
import { Logo } from 'components/content/Logo/Logo';
import { SmallPrint } from 'components/content/SmallPrint/SmallPrint';
import { MobileNav } from 'components/controls/MobileNav/MobileNav';
import { Nav } from 'components/controls/Nav/Nav';
import { FooterNav } from 'components/controls/FooterNav/FooterNav';
import { CookieBanner } from 'components/controls/CookieBanner/CookieBanner';
import { AdvancedSearch } from 'components/controls/AdvancedSearch/AdvancedSearch';
import { Logout } from 'components/controls/Logout/Logout';

const { publicRuntimeConfig: { sentryDsn } } = getConfig();

Modal.setAppElement('#__next');

Sentry.init({
  dsn: sentryDsn || '',
});

const Hyphen = styled.span`
  display: none;

  @media screen and (min-width: ${props => props.theme.breakpoint.tablet}) {
    display: inline;
  }
`;

const Links = styled.span`
  display: block;

  @media screen and (min-width: ${props => props.theme.breakpoint.tablet}) {
    display: inline;
  }
`;

interface MyAppProps extends AppProps {
  cookies: { [key: string]: any };
}

class MyApp extends App<MyAppProps> {
  state = {
    advancedOpen: false,
    menuOpen: false,
  };

  static async getInitialProps({ Component, ctx }) {
    let pageProps = {};
    if (Component.getInitialProps) {
      pageProps = await Component.getInitialProps(ctx);
    }
    pageProps = { ...pageProps, cookies: nextCookies(ctx) };
    return { pageProps };
  }

  constructor(props) {
    super(props);

    this.toggleAdvanced = this.toggleAdvanced.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
  }

  componentDidMount() {
    const { router } = this.props;
    if (router && router.events) {
      router.events.on('routeChangeComplete', () => {
        this.toggleAdvanced(false);
        this.toggleMenu(false);
      });
    }
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });

      Sentry.captureException(error);
    });

    super.componentDidCatch(error, errorInfo);
  }

  toggleAdvanced(advancedOpen) {
    this.setState({ advancedOpen });
  }

  toggleMenu(menuOpen) {
    this.setState({ menuOpen });
  }

  render() {
    const { Component, pageProps, router } = this.props;
    const { advancedOpen, menuOpen } = this.state;

    return (
      <ThemeProvider theme={theme}>
        <Head>
          {/* https://nextjs.org/docs/messages/no-document-viewport-meta */}
          <meta
            name="viewport"
            content="width=device-width,height=device-height,initial-scale=1"
          />
        </Head>
        <Normalize />
        <GlobalStyle />
        <Header>
          <Header.Logo>
            <Logo />
          </Header.Logo>
          <Header.MobileNav>
            {router.pathname === '/upload' ? (
              <MobileNav>
                <MobileNav.Item>
                  <Logout />
                </MobileNav.Item>
              </MobileNav>
            ) : (
              <MobileNav>
                <MobileNav.Item>
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      this.toggleAdvanced(true);
                    }}
                  >
                    <Icon type="search" size="xl" />
                  </a>
                </MobileNav.Item>
                <MobileNav.Item>
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      this.toggleMenu(!menuOpen);
                    }}
                  >
                    <Icon type="menu" size="xl" />
                  </a>
                </MobileNav.Item>
              </MobileNav>
            )}
            <AdvancedSearch
              type="search-only"
              isOpen={advancedOpen}
              onRequestClose={() => this.toggleAdvanced(false)}
            />
          </Header.MobileNav>
          <Header.Nav>
            {router.pathname === '/upload' ? (
              <Nav isOpen={false}>
                <Nav.Item active={false}>
                  <Logout />
                </Nav.Item>
              </Nav>
            ) : (
              <Nav isOpen={menuOpen}>
                <Nav.Item active={router.pathname === '/records'}>
                  <Link href="/records"><a>Records</a></Link>
                </Nav.Item>
                <Nav.Item active={router.pathname === '/about'}>
                  <Link href="/about"><a>About</a></Link>
                </Nav.Item>
                <Nav.Item active={router.pathname === '/contact'}>
                  <Link href="/contact"><a>Contact</a></Link>
                </Nav.Item>
              </Nav>
            )}
          </Header.Nav>
        </Header>
        <Main>
          <Component {...pageProps} />
        </Main>
        <Footer>
          <Logo alternate />
          <FooterNav>
            <FooterNav.Item>
              <Link href="/records"><a>Records</a></Link>
            </FooterNav.Item>
            <FooterNav.Item>
              <Link href="/about"><a>About</a></Link>
            </FooterNav.Item>
            <FooterNav.Item>
              <Link href="/faq"><a>FAQs</a></Link>
            </FooterNav.Item>
            <FooterNav.Item>
              <Link href="/contact"><a>Contact</a></Link>
            </FooterNav.Item>
          </FooterNav>
          <SmallPrint>
            &copy; {new Date().getFullYear()} WRAP <Hyphen>-{' '}</Hyphen>
            <Links>
              <a href="https://www.wrap.org.uk/food-waste-atlas-terms-conditions" target="_blank" rel="noopener noreferrer">Terms and conditions</a> |{' '}
              <a href="https://www.wrap.org.uk/food-waste-atlas-privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
            </Links>
          </SmallPrint>
        </Footer>
        <CookieBanner isDismissed={pageProps.cookies && pageProps.cookies.dismissed} />
      </ThemeProvider>
    );
  }
}

export default withApollo(withRouter((MyApp)));
