import { createGlobalStyle } from 'styled-components';
import { lighten } from 'polished';

export const GlobalStyle = createGlobalStyle`
  html {
    background: ${props => props.theme.colors.brandSecondary};
    box-sizing: border-box;
    line-height: ${props => props.theme.font.lineHeight};
    -webkit-text-size-adjust: 100%;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  body {
    background-color: ${props => props.theme.colors.background};
    color: ${props => props.theme.colors.bizmark};
    font-family: ${props => props.theme.font.family};
    margin: 0;
  }

  #__next {
    background-color: ${props => props.theme.colors.background};
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  h1,
  h2,
  h3,
  h4 {
    font-weight: bold;
    line-height: ${props => props.theme.heading.font.lineHeight};
    margin-top: 0;
  }

  h1 {
    font-size: ${props => props.theme.heading.font.size.h1};
  }

  h2 {
    font-size: ${props => props.theme.heading.font.size.h2};
  }

  h3 {
    font-size: ${props => props.theme.heading.font.size.h3};
  }

  h4 {
    font-size: ${props => props.theme.heading.font.size.h4};
  }

  a {
    color: ${props => props.theme.colors.brandPrimary};
    text-decoration: none;
    transition: color ${props => `${props.theme.transition.duration} ${props.theme.transition.timing}`};

    &:hover {
      color: ${props => lighten(0.1, props.theme.colors.brandPrimary)};
    }
  }

  p {
    margin: 0 0 ${props => props.theme.spacing.sm} 0;
  }

  b,
  strong {
    font-weight: bold;
  }

  img {
    max-width: 100%;
  }
`;
