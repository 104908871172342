import React from 'react';
import styled from 'styled-components';
import { transparentize } from 'polished';

const StyledNav = styled.nav`
  color: ${props => props.theme.colors.white};

  @media screen and (min-width: ${props => props.theme.breakpoint.nav}) {
    display: none;
  }
`;

const Ul = styled.ul`
  display: flex;
  flex: 1 1 auto;
  list-style: none;
  margin: 0;
  padding: 0;

  @media screen and (min-width: ${props => props.theme.breakpoint.nav}) {
    flex: 0 0 auto;
  }
`;

const Item = styled.li<{ active?: boolean }>`
  a {
    display: block;
    text-decoration: none;
    background: ${props => props.active ? props.theme.colors.darken : 'transparent'};
    color: ${props => props.theme.colors.white};
    padding: ${props => `${props.theme.spacing.sm} ${props.theme.spacing.sm}`};
    text-align: center;
    transition: background ${props => `${props.theme.transition.duration} ${props.theme.transition.timing}`};

    &:hover {
      background: ${props => transparentize(0.025, props.theme.colors.darken)};
      color: ${props => props.theme.colors.white};
    }
  }
`;

export const MobileNav = ({ children }: { children: React.ReactNode }) => (
  <StyledNav>
    <Ul>
      {children}
    </Ul>
  </StyledNav>
);


MobileNav.Item = Item;
