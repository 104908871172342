import styled from 'styled-components';
import { math } from 'polished';

interface ItemProps {
  grow?: boolean;
  shrink?: boolean;
  xs?: number;
  twoCol?: number;
  tablet?: number;
  desktop?: number;
  max?: number;
}

const breakpoints = ['xs', 'twoCol', 'tablet', 'desktop', 'max'];

const Item = styled.div<ItemProps>`
  ${props => props.grow ? 'flex-grow: 1;' : ''}
  ${props => props.shrink ? 'flex-shrink: 1;' : ''}

  ${props => breakpoints.map(bp => (
    props[`${bp}`] ? (
      `@media screen and (min-width: ${props.theme.breakpoint[`${bp}`]}) {
        min-width: ${(props[`${bp}`] / 12) * 100}%;
        width: ${(props[`${bp}`] / 12) * 100}%;
      }`
    ) : ''
  ))}
`;

interface GridProps {
  wrap?: boolean | string;
  direction?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
  justify?: 'flex-start' | 'flex-end' | 'center' | 'space-between';
  align?: 'stretch' | 'flex-start' | 'flex-end' | 'center';
  spacing?: 'xs' | 'sm' | 'md' | 'lg';
}

const StyledGrid = styled.div<GridProps>`
  display: flex;
  ${props => props.wrap ? 'flex-wrap: wrap;' : ''}
  ${props => props.direction ? `flex-direction: ${props.direction};` : ''}
  ${props => props.justify ? `justify-content: ${props.justify};` : ''}
  ${props => props.align ? `align-items: ${props.align};` : ''}
  ${props => `margin: -${math(`${props.theme.spacing[`${props.spacing || 'md'}`]} / 2`)};`}

  ${props => `
    ${Item} {
      padding: ${math(`${props.theme.spacing[`${props.spacing || 'md'}`]} / 2`)};
    }
  `}
`;

export const Grid = Object.assign(StyledGrid, { Item });
