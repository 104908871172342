import React from 'react';
import { gql, useMutation } from '@apollo/client';
import { useRouter } from 'next/router';

const LOGOUT = gql`
  mutation logout {
    logout
  }
`;

export const Logout = () => {
  const router = useRouter();
  const [logout] = useMutation(LOGOUT);
  return (
    <a
      href="/logout"
      onClick={(e) => {
        e.preventDefault();
        logout().then(() => router.push('/'));
      }}
    >
      Log out
    </a>
  );
};
