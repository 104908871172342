import React, { useState } from 'react';
import styled from 'styled-components';
import cookie from 'js-cookie';
import { Wrap } from 'components/composition/Wrap/Wrap';

const Banner = styled.div<{ dismissed: boolean }>`
  background: ${props => props.theme.colors.brandSecondary};
  color: ${props => props.theme.colors.horizon};
  padding: ${props => props.theme.spacing.md};
  position: fixed;
  left: 0;
  bottom: ${props => props.dismissed ? '-30em' : 0};
  transition: bottom ${props => `1s ${props.theme.transition.timing}`};
  width: 100%;
  z-index: 10;
  box-shadow: 0px -0.25em 0.25em ${props => props.theme.colors.darken};
`;

const Wrapper = styled(Wrap)`
  display: flex;
`;

const Content = styled.div`
  flex-grow: 1;
  padding-right: ${props => props.theme.spacing.md};

  a {
    text-decoration: underline;
  }
`;

const DismissButtonContainer = styled.div`
  flex-shrink: 0;
`;

const Dismiss = styled.button`
  appearance: none;
  background: none;
  border: 0 none;
  color: ${props => props.theme.colors.white};
  cursor: pointer;
  font-weight: bold;
  transition: background ${props => `${props.theme.transition.duration} ${props.theme.transition.timing}`};

  &:hover,
  &:focus,
  &:active {
    outline: 0;
    background: rgba(255, 255, 255, .05);
  }

  span {
    display: none;
  }

  @media(min-width: ${props => props.theme.breakpoint.tablet}) {
    span {
      display: inline;
    }
  }
`;

export const CookieBanner = ({ isDismissed }: { isDismissed?: boolean }) => {
  const [dismissed, setDismissed] = useState(cookie.get('dismissed'));

  return (
    <Banner dismissed={isDismissed || dismissed}>
      <Wrapper>
        <Content>
          Hi there! We use cookies to operate this site and give you relevant content.{' '}
          <a href="https://www.wrap.org.uk/food-waste-atlas-privacy-policy" target="_blank" rel="noopener noreferrer">Learn more</a>.
        </Content>
        <DismissButtonContainer>
          <Dismiss
            onClick={() => {
              cookie.set('dismissed', true, { path: '/', expires: 365 });
              setDismissed(true);
            }}
          >
            Okay<span>, got it!</span>
          </Dismiss>
        </DismissButtonContainer>
      </Wrapper>
    </Banner>
  );
};
