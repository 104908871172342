import React from 'react';
import styled from 'styled-components';
import { Wrap } from 'components/composition/Wrap/Wrap';

const StyledFooter = styled.footer`
  background: ${props => props.theme.colors.brandSecondary};
  margin-top: ${props => props.theme.spacing.lg};
`;

const FooterContent = styled.div`
  padding: ${props => `${props.theme.spacing.lg} ${props.theme.spacing.root}`};

  @media screen and (min-width: ${props => props.theme.breakpoint.max}) {
    padding: ${props => `${props.theme.spacing.lg} ${props.theme.spacing.max}`};
  }
`;

/** @component */
export const Footer = ({ children }: { children: React.ReactNode }) => (
  <StyledFooter>
    <Wrap>
      <FooterContent>
        {children}
      </FooterContent>
    </Wrap>
  </StyledFooter>
);
