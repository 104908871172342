import React, { useReducer, useEffect } from 'react';
import styled from 'styled-components';
import { useQuery, gql } from '@apollo/client';
import { useRouter } from 'next/router';
import pickBy from 'lodash/pickBy';

import { Grid } from 'components/composition/Grid/Grid';
import { FormGroup } from 'components/composition/FormGroup/FormGroup';

import { Modal } from 'components/canvas/Modal/Modal';

import { Spinner } from 'components/content/Spinner/Spinner';

import { Autocomplete } from 'components/controls/Autocomplete/Autocomplete';
import { Button } from 'components/controls/Button/Button';
import { Input } from 'components/controls/Input/Input';
import { Select } from 'components/controls/Select/Select';

import { noAuto } from 'lib/autocomplete';
import { niceNumber } from 'lib/numbers';

const VALUES = gql`
  query values($field: ValuesField!) {
    values(field: $field)
  }
`;

const RECORDS = gql`
  query results($region: String, $food: String, $lifecycle: String, $destination: String, $dataSource: String, $year: Int) {
    results(region: $region, food: $food, lifecycle: $lifecycle, destination: $destination, dataSource: $dataSource, year: $year) {
      count
    }
  }
`;

const YearInput = styled.div`
  max-width: 6em;
`;

const initialState = {
  lifecycle: '',
  destination: '',
  dataSource: '',
  year: '',
};

function reducer(state, { field, value }) {
  return {
    ...state,
    [field]: value,
  };
}

interface AdvancedSearchProps {
  isOpen?: boolean;
  onRequestClose: (event: React.MouseEvent<HTMLButtonElement>) => void;
  type?: 'search' | 'filter' | 'search-only';
  regionValue?: string;
  foodValue?: string;
}

export const AdvancedSearch = ({
  isOpen = true,
  onRequestClose,
  type = 'search',
  regionValue = '',
  foodValue = '',
}: AdvancedSearchProps) => {
  const router = useRouter();

  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    region: regionValue,
    food: foodValue,
    ...router.query,
  });

  const { data: lifecycleValues } = useQuery(VALUES, { variables: { field: 'LIFECYCLE' }, skip: !isOpen });
  const { data: destinationValues } = useQuery(VALUES, { variables: { field: 'DESTINATION' }, skip: !isOpen });
  const { region, food, lifecycle, destination, dataSource, year } = state;

  // Update state from props
  useEffect(() => {
    dispatch({ field: 'region', value: regionValue });
    dispatch({ field: 'food', value: foodValue });
  }, [regionValue, foodValue]);

  const getQuery = () => {
    let values;

    if (type === 'search-only') {
      values = {
        region: state.region,
        food: state.food,
      };
    } else {
      values = {
        ...state,
        region: type === 'filter' ? router.query.region : state.region,
        food: type === 'filter' ? router.query.food : state.food,
        year: state.year !== '' ? Number(state.year) : '',
      };
    }

    return pickBy(values, (item) => (item && item !== ''));
  };

  const getTitle = () => {
    if (type === 'filter') {
      return 'Filter results';
    }

    if (type === 'search-only') {
      return 'Search records';
    }

    return 'Advanced search';
  };

  const handleChange = ({ target: { name, value } }) => {
    dispatch({
      field: name,
      value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const query = getQuery();

    await router.push({
      pathname: '/records',
      query,
    });
    onRequestClose(e);
    window.scrollTo(0, 0);
  };

  const { data: records, loading: recordsLoading } = useQuery(RECORDS, {
    variables: getQuery(),
    context: {
      debounceKey: 'recordCount',
      debounceTimeout: 750,
    },
    skip: !isOpen,
  });

  const cantSubmit = !records
    || (records && !records.results)
    || (records && records.results && !records.results.count);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
    >
      <h2>
        {getTitle()}
      </h2>

      <form method="GET" action="/records" autoComplete="off" onSubmit={handleSubmit}>
        {type === 'search' || type === 'search-only' ? (
          <>
            <FormGroup>
              <FormGroup.Label htmlFor="advanced-region">
                Region
                <small>Where abouts the food waste occured</small>
              </FormGroup.Label>
              <FormGroup.Content>
                <Autocomplete
                  field="REGION"
                  id="advanced-region"
                  name="region"
                  placeholder="Any country or city..."
                  value={region}
                  handleChange={value => handleChange({
                    target: { name: 'region', value },
                  })}
                  autoComplete={noAuto()}
                />
              </FormGroup.Content>
            </FormGroup>

            <FormGroup>
              <FormGroup.Label htmlFor="advanced-food">
                Food
                <small>Which type of food was wasted</small>
              </FormGroup.Label>
              <FormGroup.Content>
                <Autocomplete
                  field="FOOD"
                  name="food"
                  id="advanced-food"
                  placeholder="Any food type or group..."
                  value={food}
                  handleChange={value => handleChange({
                    target: { name: 'food', value },
                  })}
                  autoComplete={noAuto()}
                />
              </FormGroup.Content>
            </FormGroup>
          </>
        ) : null}

        {type !== 'search-only' ? (
          <>
            <FormGroup>
              <FormGroup.Label htmlFor="lifecycle">
                Lifecycle Stage
                <small>The stage in the food supply chain that the waste occurred</small>
              </FormGroup.Label>
              <FormGroup.Content>
                <Select>
                  <select
                    name="lifecycle"
                    id="lifecycle"
                    value={lifecycle}
                    onChange={handleChange}
                  >
                    <option value="">Any stage</option>
                    {lifecycleValues && lifecycleValues.values ? (
                      [...lifecycleValues.values].sort().map(value => (
                        <option value={value} key={value}>{value}</option>
                      ))
                    ) : null}
                  </select>
                </Select>
              </FormGroup.Content>
            </FormGroup>

            <FormGroup>
              <FormGroup.Label htmlFor="destination">
                Destination
                <small>How the waste was disposed</small>
              </FormGroup.Label>
              <FormGroup.Content>
                <Select>
                  <select
                    name="destination"
                    id="destination"
                    value={destination}
                    onChange={handleChange}
                  >
                    <option value="">Any destination</option>
                    {destinationValues && destinationValues.values ? (
                      [...destinationValues.values].sort().map(value => (
                        <option value={value} key={value}>{value}</option>
                      ))
                    ) : null}
                  </select>
                </Select>
              </FormGroup.Content>
            </FormGroup>

            <FormGroup>
              <FormGroup.Label htmlFor="dataSource">
                Data Source
                <small>Who provided the data or where the data came from</small>
              </FormGroup.Label>
              <FormGroup.Content>
                <Autocomplete
                  field="DATA_SOURCE"
                  name="dataSource"
                  id="dataSource"
                  placeholder="enter a source name..."
                  value={dataSource}
                  handleChange={value => handleChange({
                    target: { name: 'dataSource', value },
                  })}
                  autoComplete={noAuto()}
                />
              </FormGroup.Content>
            </FormGroup>

            <FormGroup>
              <FormGroup.Label htmlFor="year">
                Year
                <small>The year the data represents</small>
              </FormGroup.Label>
              <FormGroup.Content>
                <YearInput>
                  <Input
                    type="number"
                    name="year"
                    id="year"
                    placeholder="YYYY"
                    min={1933}
                    max={2500}
                    value={year}
                    onChange={handleChange}
                    autoComplete={noAuto()}
                  />
                </YearInput>
              </FormGroup.Content>
            </FormGroup>
          </>
        ) : null}

        <Grid align="center" justify="space-between">
          <Grid.Item>
            {records && records.results && records.results ? (
              <span>
                {niceNumber(records.results.count)}{records.results.count === 10000 ? '+' : ''}{' '}
                record{records.results.count !== 1 ? 's' : ''}
              </span>
            ) : null}
          </Grid.Item>
          <Grid.Item>
            <Button
              type="submit"
              disabled={cantSubmit}
            >
              Show Results <Spinner show={recordsLoading} />
            </Button>
          </Grid.Item>
        </Grid>
      </form>
    </Modal>
  );
};
